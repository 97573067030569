import React from 'react';
import { Link } from 'react-router-dom';
import BookAppointment from './BookAppointmentButton';

const LetsTalk = () => {
    return (
        <div className="container mx-auto px-5 mt-10 mb-10">
            <div className="flex flex-col items-center justify-center">
                <h1 className="text-5xl text-[#126CB3] my-font font-bold mb-6">LET'S TALK</h1>
                <p className="text-lg mb-6 text-[#1488C0]">We would love to hear from you!</p>
            </div>
            {/* Button */}
            <div className='justify-center items-center flex'>
                <BookAppointment />
            </div>
        </div>
    );
};

export default LetsTalk;
