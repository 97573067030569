import React from 'react';
import house10 from '../../../assets/img/houses/house10lg.png'
import shaheerA from '../../../assets/img/agents/shaheerA.jpeg'
import { useForm } from "react-hook-form";

// import icons
import { BiBed, BiBath, BiArea, BiPhone } from 'react-icons/bi';
// import link
import { Link } from 'react-router-dom';
import WoodsRdPastCarousel from './WoodsRdPastCarousel';

const WoodsRd = () => {
  const {
    register,
    trigger,
    formState: { errors },
  } = useForm();

  const onSubmit = async (e) => {
    console.log("~ e", e);
    const isValid = await trigger();
    if (!isValid) {
      e.preventDefault();
    }
  };

  return (
    <div className='container mx-auto min-h-[800px] mb-14 mt-5'>
      <div className='flex flex-col lg:flex-row lg:items-center lg:justify-between'>
        <div>
          <h2 className='text-2xl font-semibold'>Property Name</h2>
          <div className='flex'>
            <h3 className='text-lg mb-4'>403 Woods Road, Des Plaines, IL 60016</h3>
            <a href='https://www.redfin.com/IL/Des-Plaines/403-Woods-Rd-60016/home/13666150' className='text-lg mb-4 ml-5 underline text-blue-500'>More Details</a>
          </div>
        </div>
        <div className='text-3xl font-semibold text-blue-600'>
          {/* $ Property Price */}
        </div>
      </div>
      <div className='flex flex-col items-start gap-8 lg:flex-row'>
        <div className='max-w-[768px]'>
          <div className='mb-8'>
            {/* <img src={house10} alt='house10' /> */}
            <WoodsRdPastCarousel/>
          </div>
          <div className='flex gap-x-6 text-blue-700 mb-6'>
            <div className='flex gap-x-2 items-center'>
              <BiBed className='text-2xl' />
              <div className='text-lg font-medium'>5</div>
            </div>
            <div className='flex gap-x-2 items-center'>
              <BiBath className='text-2xl' />
              <div className='text-lg font-medium'>3.5</div>
            </div>
            <div className='flex gap-x-2 items-center'>
              <BiArea className='text-2xl' />
              <div className='text-lg font-medium'>3002 sq ft</div>
            </div>
          </div>
          <p>Complete Gut Rehab Inside & Out! Stunning 5 Bedroom, 3.1 Bath Home with 2 Car Garage on . 41 acres. Exquisite Architectural Details Highlight this Impeccably Renovated Home w/ Hardwood Floors Throughout. 2 Lovely Entryways and Spacious Foyer w/ Half Bath. Large Living Room with Gorgeous Stone Gas Fireplace, Separate Dining Room Large Enough for all your Family Gatherings. Chef's Kitchen Features Thomasville Cabinetry w/ Granite Countertops and Island, all SS Appliances, and Large Pantry. Family Room with Heatilator Fireplace Plus an Additional Den and Mud Room. Master Suite Features a Luxurious Bath, Walk In Closet, and Sitting Area. 4 Additional Bedrooms w/ 2nd Bedroom being an Ensuite, All Feature Custom Closets. All 3 Full Baths Have Heated Floors. 2nd Level Laundry Room. Enormous 3rd Level Finished Rec Room. Huge Yard Professionally Landscaped w/ Mature Trees. This is a Unique and Artfully Executed Home w/ High End Designer Finishes Ready for You to Just Move in and Enjoy!</p>
          <br />
          <p className='italic font-bold'>
          Our management acquired this property after it had caught on fire. We then redeveloped it and professionally interior designed/rehabbed the property under the direction of our lead interior designer to bring life to this priceless abode.
          </p>
        </div>
        <div className='flex-1 w-full mb-8 bg-white border border-gray-300 rounded-lg px-6 py-8'>
          <div className='flex items-center gap-x-4 mb-8'>
            <div className='w-20 h-20 p-1 border border-gray-300 rounded-full'>
              <img src={shaheerA} className='rounded-full' alt='agent image'></img>
            </div>
            <div>
              <div className='font-bold text-lg'>Shaheer Anwar</div>
              <Link to='/portfolio' className='text-blue-700 text-sm'>
                View listings
              </Link>
            </div>
          </div>
          <form className='flex flex-col gap-y-4' onSubmit={onSubmit} action="https://formsubmit.co/support@skarealtypros.com" method="POST">
            <input
              className='border border-gray-300 focus:border-blue-700 rounded w-full px-4 h-14 text-sm outline-none'
              type='text'
              placeholder='Name*'
              {...register("name", {
                required: true,
                maxLength: 100,
              })}
            />
            {errors.name && (
              <p className="text-red-500 mt-1 text-left">
                {errors.name.type === "required" && "This field is required."}
                {errors.name.type === "maxLength" && "Max length is 100 characters."}
              </p>
            )}
            <input
              className='border border-gray-300 focus:border-blue-700 rounded w-full px-4 h-14 text-sm outline-none'
              type='text'
              placeholder='Phone*'
              {...register("phone", {
                required: true,
              })}
            />
            {errors.phone && (
              <p className="text-red-500 mt-1 text-left">
                {errors.phone.type === "required" && "This field is required."}
              </p>
            )}
            <textarea
              className='border border-gray-300 focus:border-blue-700 rounded w-full p-4 h-36 text-sm text-gray-400 outline-none resize-none'
              type='text'
              placeholder='Hello, I am interested in this listing...'
              {...register("message", {
                required: true,
                maxLength: 2000,
              })}
            />
            {errors.message && (
              <p className="text-red-500 mt-1 text-left">
                {errors.message.type === "required" && "This field is required."}
                {errors.message.type === "maxLength" && "Max length is 2000 characters."}
              </p>
            )}
            <div className='flex gap-x-2'>
              <button
                className='bg-blue-700 hover:bg-blue-800 text-white rounded p-4 text-sm w-full transition'
                type='submit'
              >
                Send message
              </button>
              <a href="https://calendly.com/abrarhussain-00/30min" className='border border-blue-700 text-blue-700 hover:border-purple-600 hover:text-purple-600 rounded p-4 text-sm w-full transition block text-center'>Book a call</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default WoodsRd;
