import React from 'react';
import ourLogo from '../assets/img/ourLogo.jpeg'

const Footer = () => {
    return (
        <div>
            <div className="border-b-2 border-gray-300"></div>
            <footer className="m-10">
                <div className="flex flex-col md:flex-row justify-center items-center">
                    <div className="mb-8 md:mb-0">
                        <a href='/'><img src={ourLogo} height={200} width={200} alt="Logo" /></a>
                    </div>
                    <ul className="flex flex-wrap justify-center md:justify-start gap-4 md:flex-col md:items-center md:gap-0">
                        {/* <li>
                            <a href="/home" className="text-black-500 hover:text-[#126CB3]">Home</a>
                        </li> */}
                        <li>
                            <a href="/portfolio" className="text-black-500 hover:text-[#126CB3]">Portfolio</a>
                        </li>
                        <li>
                            <a href="/services" className="text-black-500 hover:text-[#126CB3]">Services</a>
                        </li>
                        <li>
                            <a href="/about" className="text-black-500 hover:text-[#126CB3]">About</a>
                        </li>
                        {/* <li>
                            <a href="/testimonials" className="text-black-500 hover:text-[#126CB3]">Testimonials</a>
                        </li> */}
                        <li>
                            <a href="/contact" className="text-black-500 hover:text-[#126CB3]">Contact</a>
                        </li>
                    </ul>
                </div>
                {/* <hr className="border-b-2 border-gray-300 my-6" /> */}
                {/* <div className="flex justify-center items-center gap-4">
                    Spotify
                    <img src="https://img.icons8.com/?size=512&id=11116&format=png" width="35px" alt="Spotify" />
                    Facebook
                    <img src="https://img.icons8.com/?size=512&id=118490&format=png" width="35px" alt="Facebook" />
                    Pinterest
                    <img src="https://img.icons8.com/?size=512&id=11101&format=png" width="35px" alt="Pinterest" />
                    Instagram
                    <img src="https://img.icons8.com/?size=512&id=85140&format=png" width="35px" alt="Instagram" />
                    Twitter
                    <img src="https://img.icons8.com/?size=512&id=60452&format=png" width="35px" alt="Twitter" />
                </div> */}
            </footer>

            <div className="flex justify-center items-center bg-white text-black hover:text-blue-400 px-10 py-10">
                <a href='https://www.webpaladin.dev/' target="_blank" className="text-center">
                    Made © Web Paladin. All rights reserved. Looking to have your website built?
                </a>
            </div>
        </div>
    );
};

export default Footer;
