import React from 'react';
import { Link } from 'react-router-dom';
import AllServicesButton from './AllServicesButton';

const HomeServices = () => {
    return (
        <div className="container mx-auto  mt-3 mb-10 md:max-w-lg">
            {/* What we do */}
            <div className="text-center">
            <h1 className="text-3xl text-gray-400 my-font mb-3"><span className='text-[#126CB3] font-bold text-[35px] justify-center items-center flex'>What We Do</span> </h1></div>

            {/* Learn more about the services we offer */}
            <div className="text-center">
            <p className="text-[24px] font-bold my-font mb-6 text-gray-700 justify-center items-center flex">Learn more about the services we offer</p></div>

            {/* Description */}
            <p className="my-font text-[18px] mb-10">
            <div className="text-center">
                <p>
                    SKA Realty Pros offers a way for landlords to receive guaranteed rental income for life (exclusions may apply) through corporate travel reservations and flexible furnished housing platforms. 
                </p>
                <br/><br/>
                <span className='text-[28px] text-[#5898c9]' style={{marginTop:'10px' , marginBottom:'10px'}}>But that's not all...</span>
                <br/><br/>
                <p>We also bring your property to life with our professional interior design and staging consultancy services. Under the guidance of our lead interior designer, our team will transform your space into a captivating oasis, designed to attract tenants or buyers and maximize your rental or selling potential.</p>
            </div>
            </p>
            <div className='justify-center items-center flex'>
                <AllServicesButton/>
            </div>
        </div>
    );
};

export default HomeServices;
