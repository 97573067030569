import React from 'react';
import { Link } from 'react-router-dom';
import servicesHouse from '../assets/img/houses/servicesHouse.jpeg';
import useMediaQuery from '../utils/useMediaQuery';

const ServiceBanner = () => {
    const isAboveSmallScreens = useMediaQuery('(min-width:768px)');

    return (
        isAboveSmallScreens ? (
            // large window
            // <div className="relative">
            //     {/* Main Section */}
            //     <main
            //         className="p-5 md:p-10 text-center bg-image services-main relative rounded-lg rounded-bl-[90px] min-h-[65vh] bg-cover bg-center"
            //         style={{
            //             backgroundImage: `url(${servicesHouse})`,
            //         }}
            //     >
            //         {/* White Box Overlay */}
            //         <div className="absolute top-0 left-0 bg-white px-5 py-3 md:px-10 md:py-6 w-full md:w-[500px] sm:w-[300px] rounded-lg rounded-br-[90px]">
            //             <div className="text-left">
            //                 {/* Title */}
            //                 <h1 className="mt-6 mb-3 text-2xl md:mt-9 md:mb-1 md:text-3xl text-white-800">
            //                     Find the ideal tenant in the US and maximize your rental income
            //                 </h1>
            //                 {/* Subtitle */}
            //                 <h4 className="mb-5 text-sm md:text-base">
            //                     List your property in the US with Blueground and forget the rest. From revenue sharing to standard leasing agreements, we manage the entire process end to end.
            //                 </h4>
            //                 {/* Buy Now Button */}
            //                 <Link to="/contact" className="nav-link nav-link-contact mt-3 p-5">
            //                     Buy Now
            //                 </Link>
            //             </div>
            //         </div>
            //     </main>
            // </div>

            // shaheer like this better
            <div className="relative">
                {/* Main Section */}
                <main
                    className="p-5 md:p-10 text-center bg-image services-main relative rounded-lg min-h-[65vh] bg-cover bg-center"
                    style={{
                        backgroundImage:
                            "url('https://germaniaconstruction.com/wp-content/uploads/2022/11/park-city-custom-home-builder-great-room.jpg')",
                    }}
                >
                    {/* Background Image */}
                    <div className="absolute top-0 left-0 w-full h-full bg-opacity-50 bg-black"></div>

                    {/* White Box Overlay */}
                    <div
                        style={{
                            backgroundColor: 'rgba(0, 0, 0, 0.25)',
                            padding: '30px',
                            borderRadius: '',
                            padding: '50px',
                            marginTop: '2vh',
                            position: 'relative', // Add this line to position the content inside the overlay
                            zIndex: 1, // Add this line to increase the z-index of the overlay content
                        }}
                    >
                        <div className="text-center h-100">
                            <div className="text-white">
                                <h1 className="mt-8 mb-5 text-2xl text-white">
                                    Unlock maximum rental income by partnering with the most stellar tenant in the US.
                                </h1>
                                <h4 className="mb-8 text-sm">
                                    From the meticulous maintenance of your property to the meticulous crafting of leasing agreements and vetting guests, we adeptly oversee the complete spectrum of operations from end to end.
                                </h4>
                                {/* Buy Now Button */}
                                {/* <Link
                                    to="/contact"
                                    className="nav-link nav-link-contact mt-3 p-5"
                                    style={{ display: 'inline-block', backgroundColor: '#fff', color: '#000' }}
                                >
                                    Buy Now
                                </Link> */}
                                <Link
                                    to="/contact"
                                    className="py-2 px-4 border border-[#126CB3] bg-[#126CB3] text-white my-font"
                                >
                                    Contact Us
                                </Link>
                            </div>
                        </div>
                    </div>
                </main>
            </div>

        ) : (
            // small window
            <div className="relative">
                {/* Main Section */}
                <main
                    className="p-5 md:p-10 text-center bg-image services-main relative rounded-lg min-h-[65vh] bg-cover bg-center"
                    style={{
                        backgroundImage:
                            "url('https://germaniaconstruction.com/wp-content/uploads/2022/11/park-city-custom-home-builder-great-room.jpg')",
                    }}
                >
                    {/* Background Image */}
                    <div className="absolute top-0 left-0 w-full h-full bg-opacity-50 bg-black"></div>

                    {/* White Box Overlay */}
                    <div
                        style={{
                            backgroundColor: 'rgba(0, 0, 0, 0.25)',
                            padding: '30px',
                            borderRadius: '',
                            marginTop: '2vh',
                            position: 'relative', // Add this line to position the content inside the overlay
                            zIndex: 1, // Add this line to increase the z-index of the overlay content
                        }}
                    >
                        <div className="text-center h-100">
                            <div className="text-white">
                                <h1 className="mt-8 mb-5 text-2xl text-white">
                                    Unlock maximum rental income by partnering with the most stellar tenant in the US.
                                </h1>
                                <h4 className="mb-8 text-sm">
                                    From the meticulous maintenance of your property to the meticulous crafting of leasing agreements and vetting guests, we adeptly oversee the complete spectrum of operations from end to end.
                                </h4>
                                {/* Buy Now Button */}
                                {/* <Link
                                    to="/contact"
                                    className="nav-link nav-link-contact mt-3 p-5"
                                    style={{ display: 'inline-block', backgroundColor: '#fff', color: '#000' }}
                                >
                                    Buy Now
                                </Link> */}
                                <Link
                                    to="/contact"
                                    className="py-2 px-4 border border-[#126CB3] bg-[#126CB3] text-white my-font"
                                >
                                    Contact Us
                                </Link>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        )
    );
};

export default ServiceBanner;
