import React from 'react';
import { Link } from 'react-router-dom';

const Banner = () => {
    return (
        <div className="relative flex flex-col justify-center items-center min-h-screen">
            {/* Background Image */}
            <div className="absolute top-0 left-0 w-full h-full opacity-20 z-0">
                <img
                    src="https://foyr.com/learn/wp-content/uploads/2019/02/interior-design-ideas-for-living-room.jpg"
                    alt="Background"
                    className="w-full h-full object-cover"
                />
            </div>

            {/* Content */}
            <div className="text-black text-center z-10">
                <h2 className="text-5xl my-font" style={{ fontWeight: 900 }}><span className='text-[#126CB3] font-bold'>SKA Realty Pros</span></h2>
                <br/>
                <h2 className='text-xl font-bold my-font'>Real Estate Developing, Rehabbing, & Managing</h2>
                <p className="text-lg my-6 my-font text-gray-800 ">
                    We specialize in providing landlords the beginning to a never ending expansion 
                    <br/>
                    of properties and a life long passive rent partnership (exclusions may apply).
                </p>
                <Link
                    to="/contact"
                    className="mt-6 py-2 px-4 border border-[#126CB3] text-white bg-[#126CB3] hover:text-white my-font"
                >
                    Book an appointment
                </Link>
            </div>
        </div>
    );
};

export default Banner;
