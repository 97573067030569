import React from 'react';
import { Link } from 'react-router-dom';
import servicesHouse from '../assets/img/houses/servicesHouse.jpeg';
import useMediaQuery from '../utils/useMediaQuery';

const ServiceBanner = () => {
    const isAboveSmallScreens = useMediaQuery('(min-width:768px)');

    return (
        isAboveSmallScreens ? (
            // shaheer like this better
            <div className="relative">
                {/* Main Section */}
                <main
                    className="p-5 md:p-10 text-center bg-image services-main relative rounded-lg min-h-[65vh] bg-cover bg-center"
                    style={{
                        backgroundImage:
                            "url('https://cdn.homedit.com/wp-content/uploads/2020/12/Modern-Home-Decor-Ideas-for-Lovers-and-Skeptics-Alike.jpg')",
                    }}
                >
                    {/* Background Image */}
                    <div className="absolute top-0 left-0 w-full h-full bg-opacity-50 bg-black"></div>

                    {/* White Box Overlay */}
                    <div
                        style={{
                            backgroundColor: 'rgba(0, 0, 0, 0.25)',
                            padding: '30px',
                            borderRadius: '',
                            padding: '50px',
                            marginTop: '2vh',
                            position: 'relative', // Add this line to position the content inside the overlay
                            zIndex: 1, // Add this line to increase the z-index of the overlay content
                        }}
                    >
                        <div className="text-center h-100">
                            <div className="text-white">
                                <h1 className="mt-8 mb-5 text-2xl text-white">
                                    Impress Your Potential Guests
                                </h1>
                                <h4 className="mb-8 text-sm">
                                    Every aspect of your property is meticulously curated by our lead interior designer (utilizing 3D photorealistic renderings, elevations, current trends, and 8 years of industry insight), photographed using professional services and high dynamic range (HDR) imaging, and an experience that leaves viewers in awe of your gorgeous property. 
                                </h4>
                                {/* Buy Now Button */}
                                {/* <Link
                                    to="/contact"
                                    className="nav-link nav-link-contact mt-3 p-5"
                                    style={{ display: 'inline-block', backgroundColor: '#fff', color: '#000' }}
                                >
                                    Buy Now
                                </Link> */}
                                <Link
                                    to="/contact"
                                    className="py-2 px-4 border border-[#126CB3] bg-[#126CB3] text-white my-font"
                                >
                                    Contact Us
                                </Link>
                            </div>
                        </div>
                    </div>
                </main>
            </div>

        ) : (
            // small window
            <div className="relative">
                {/* Main Section */}
                <main
                    className="p-5 md:p-10 text-center bg-image services-main relative rounded-lg min-h-[65vh] bg-cover bg-center"
                    style={{
                        backgroundImage:
                            "url('https://cdn.homedit.com/wp-content/uploads/2020/12/Modern-Home-Decor-Ideas-for-Lovers-and-Skeptics-Alike.jpg')",
                    }}
                >
                    {/* Background Image */}
                    <div className="absolute top-0 left-0 w-full h-full bg-opacity-50 bg-black"></div>

                    {/* White Box Overlay */}
                    <div
                        style={{
                            backgroundColor: 'rgba(0, 0, 0, 0.25)',
                            padding: '30px',
                            borderRadius: '',
                            marginTop: '2vh',
                            position: 'relative', // Add this line to position the content inside the overlay
                            zIndex: 1, // Add this line to increase the z-index of the overlay content
                        }}
                    >
                        <div className="text-center h-100">
                            <div className="text-white">
                                <h1 className="mt-8 mb-5 text-2xl text-white">
                                    Impress Your Potential Guests
                                </h1>
                                <h4 className="mb-8 text-sm">
                                    Every aspect of your property is meticulously curated by our lead interior designer (utilizing 3D photorealistic renderings, elevations, current trends, and 8 years of industry insight), photographed using professional services and high dynamic range (HDR) imaging, and an experience that leaves viewers in awe of your gorgeous property. 
                                </h4>
                                {/* Buy Now Button */}
                                {/* <Link
                                    to="/contact"
                                    className="nav-link nav-link-contact mt-3 p-5"
                                    style={{ display: 'inline-block', backgroundColor: '#fff', color: '#000' }}
                                >
                                    Buy Now
                                </Link> */}
                                <Link
                                    to="/contact"
                                    className="py-2 px-4 border border-[#126CB3] bg-[#126CB3] text-white my-font"
                                >
                                    Contact Us
                                </Link>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        )
    );
};

export default ServiceBanner;
