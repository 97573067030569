import React, { useState, useEffect } from 'react';
import Navbar from './components/Navbar';
import { Route, Routes } from 'react-router-dom';
import Home from './views/Home';
import Portfolio from './views/Portfolio';
import Footer from './components/Footer';
import Contact from './views/Contact';
import About from './views/About';
import Services from './views/Services';
import Testimonials from './views/Testimonials';

// REHABBED PROPERTIES
import WoodsRd from './views/rehabbedProperties/WoodsRd/WoodsRd';
import Beckwith from './views/rehabbedProperties/BeckwithRd/BeckWithRd';
import BolingBrokeDr from './views/rehabbedProperties/BolingBrokeDr/BolingBrokeDr';
import WoodviewLn from './views/rehabbedProperties/WoodviewLn/WoodviewLn'
import RankinAve from './views/rehabbedProperties/RankinAve/RankinAve'

// PAST DEVELOPED PROPERTIES
import WoodsRdPast from './views/pastDevelopedProperties/WoodsRdPast/WoodsRdPast'
import StateRt from './views/pastDevelopedProperties/StateRt/StateRt'

// FURNISHED PROPERTIES
import WoodviewLnFurnished from './views/furnishedProperties/WoodviewLnFurnished/WoodviewLnFurnished'

function App() {
  const [isFollowing, setIsFollowing] = useState(false);

  // Add scroll event listener to toggle logo following
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsFollowing(true);
      } else {
        setIsFollowing(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/testimonials" element={<Testimonials />} />

        {/* Rehabbed Properties */}
        <Route path="/403-woods-road" element={<WoodsRd />} />
        <Route path="/7212-beckwith-road" element={<Beckwith />} />
        <Route path="/2909-bolingbroke-drive" element={<BolingBrokeDr />} />
        <Route path="/2160-woodview-ln" element={<WoodviewLn />} />
        <Route path="/2420-rankin-ave" element={<RankinAve />} />

        {/* Past Developed Properties */}
        <Route path="/403-woods-road-past" element={<WoodsRdPast />} />
        <Route path="/54204-state-route" element={<StateRt />} />

        {/* Furnished Properties */}
        <Route path="/2160-woodview-ln-furnished" element={<WoodviewLnFurnished />} />
      </Routes>
      <Footer />
      {/* Add conditional classes for the logo */}
      <img
        src="https://i.ibb.co/nQv29VV/favicon.png"
        alt="Logo"
        className={`logo ${isFollowing ? ' fixed bottom-0 right-0 transform translate-x-1/2 translate-y-1/2' : 'relative'}`}
        onClick={() => window.location.href = 'https://www.webpaladin.dev/'}
        width={50}
        style={{ opacity: '0.5', transition: 'opacity 0.3s' }}
        onMouseOver={(e) => e.currentTarget.style.opacity = '1'}
        onMouseOut={(e) => e.currentTarget.style.opacity = '0.5'}
      />
    </div>
  );
}

export default App;
