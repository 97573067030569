import React, { useState, useEffect } from 'react';
import useMediaQuery from '../../../utils/useMediaQuery';
import Card from '../../../components/Card';
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

const WoodsViewLnCarousel = () => {
    // desktop window
    const [activeIndex, setActiveIndex] = useState(0);

    const handleToggleClick = (index) => {
        setActiveIndex(index);
    };

    const handlePrev = () => {
        const newIndex = activeIndex === 0 ? 9 : activeIndex - 1;
        setActiveIndex(newIndex);
    };

    const handleNext = () => {
        const newIndex = activeIndex === 9 ? 0 : activeIndex + 1;
        setActiveIndex(newIndex);
    };

    // Mobile window

    const isAboveSmallScreens = useMediaQuery('(min-width:768px)');
    const [currentIndex, setCurrentIndex] = useState(0);
    const images = [
        'https://ssl.cdn-redfin.com/photo/68/bigphoto/813/10381813_2_6.jpg',
        "https://ssl.cdn-redfin.com/photo/68/bigphoto/813/10381813_4_6.jpg",
        "https://ssl.cdn-redfin.com/photo/68/bigphoto/813/10381813_8_6.jpg",
        "https://ssl.cdn-redfin.com/photo/68/bigphoto/813/10381813_11_6.jpg",
        "https://ssl.cdn-redfin.com/photo/68/bigphoto/627/10373627_2.jpg",
        "https://ssl.cdn-redfin.com/photo/68/bigphoto/627/10373627_2_2.jpg",
        "https://ssl.cdn-redfin.com/photo/68/bigphoto/627/10373627_4_2.jpg",
        "https://ssl.cdn-redfin.com/photo/68/bigphoto/627/10373627_5_2.jpg",
        "https://ssl.cdn-redfin.com/photo/68/bigphoto/627/10373627_12_2.jpg",
        "https://ssl.cdn-redfin.com/photo/68/bigphoto/627/10373627_17_2.jpg"
    ];

    const scrollLeft = () => {
        setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : images.length - 1));
    };

    const scrollRight = () => {
        setCurrentIndex((prevIndex) => (prevIndex < images.length - 1 ? prevIndex + 1 : 0));
    };

    useEffect(() => {
        const interval = setInterval(() => {
            scrollRight();
        }, 2000);
        return () => {
            clearInterval(interval);
        };
    }, []);


    return (
        // large window
        isAboveSmallScreens ? (
            <div className="relative w-full">
                {/* Carousel images */}
                <div className="relative flex items-center justify-center">
                    {/* Carousel controls - prev item */}
                    <button
                        className="absolute left-0 z-10 flex items-center justify-center border-0 bg-none p-0 text-center text-black opacity-50 hover:text-black hover:no-underline hover:opacity-90 hover:outline-none focus:text-black focus:no-underline focus:opacity-90 focus:outline-none"
                        type="button"
                        onClick={handlePrev}
                    >
                        <FiChevronLeft className="h-20 w-20" />
                    </button>

                    {/* Carousel images */}
                    <div className="relative">
                        {/* First to Tenth items */}
                        {images.map((image, index) => (
                            <div
                                key={index}
                                className={`relative transition-opacity duration-300 ease-in-out ${activeIndex === index ? 'opacity-100' : 'opacity-0 hidden'
                                    }`}
                                data-te-carousel-item
                            >
                                <div className="w-full h-[400px]">
                                    <img src={image} className="block w-full h-full object-cover" alt={`Image ${index}`} />
                                </div>
                            </div>
                        ))}
                    </div>
                    {/* Add more items if needed */}

                    {/* Carousel controls - next item */}
                    <button
                        className="absolute right-0 z-10 flex items-center justify-center border-0 bg-none p-0 text-center text-black opacity-50 hover:text-black hover:no-underline hover:opacity-90 hover:outline-none focus:text-black focus:no-underline focus:opacity-90 focus:outline-none"
                        type="button"
                        onClick={handleNext}
                    >
                        <FiChevronRight className="h-20 w-20" />
                    </button>
                </div>

                {/* Carousel indicators */}
                <div className="absolute bottom-0 left-0 right-0 z-10 mx-auto mb-4 flex list-none justify-center p-0" data-te-carousel-indicators>
                    {images.map((_, index) => (
                        <button
                            key={index}
                            type="button"
                            className={`mx-1 box-content h-3 w-3 flex-initial cursor-pointer rounded-full border-0 bg-white opacity-50 hover:opacity-90 hover:outline-none focus:opacity-90 focus:outline-none ${activeIndex === index ? 'opacity-100' : ''
                                }`}
                            onClick={() => handleToggleClick(index)}
                        ></button>
                    ))}
                </div>
            </div>

            // small window
        ) : (
            <div className="relative">
                <br />
                <div
                    id="content"
                    className="carousel p-4 
            flex items-center
            justify-start overflow-x-auto
            relative gap-8
            scroll-smooth
            scrollbar-hide"
                >
                    <div>
                        <Card image={images[currentIndex]} />
                    </div>
                </div>
            </div>
        )
    );
};

export default WoodsViewLnCarousel;
