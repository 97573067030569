import React, { useState, useEffect } from 'react';
import useMediaQuery from '../utils/useMediaQuery';
import house7 from '../assets/img/houses/house7lg.png';
import house8 from '../assets/img/houses/house8lg.png';
// import house9 from '../assets/img/houses/house9lg.png';
import carousel1 from '../assets/img/houses/carousel1.jpg';
import carousel2 from '../assets/img/houses/carousel2.jpg';
import carousel3 from '../assets/img/houses/carousel3.jpg';
import carousel4 from '../assets/img/houses/carousel4.jpg';
import carousel5 from '../assets/img/houses/carousel5.jpg';
import carousel6 from '../assets/img/houses/carousel6.jpg';
import carousel7 from '../assets/img/houses/carousel7.jpg';
import carousel8 from '../assets/img/houses/carousel8.jpg';
import carousel9 from '../assets/img/houses/carousel9.jpg';
import carousel10 from '../assets/img/houses/carousel10.jpg';
import house4 from '../assets/img/houses/house4lg.png'

import Card from "./Card";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

const Carousel = () => {
    // desktop window
    const [activeIndex, setActiveIndex] = useState(0);

    const handleToggleClick = (index) => {
        setActiveIndex(index);
    };

    const handlePrev = () => {
        const newIndex = activeIndex === 0 ? 9 : activeIndex - 1;
        setActiveIndex(newIndex);
    };

    const handleNext = () => {
        const newIndex = activeIndex === 9 ? 0 : activeIndex + 1;
        setActiveIndex(newIndex);
    };

    // Mobile window

    const isAboveSmallScreens = useMediaQuery('(min-width:768px)');
    const [currentIndex, setCurrentIndex] = useState(0);
    const images = [
        'https://ssl.cdn-redfin.com/photo/68/bigphoto/813/10381813_2_6.jpg',
        "https://ssl.cdn-redfin.com/photo/68/bigphoto/813/10381813_4_6.jpg",
        "https://ssl.cdn-redfin.com/photo/68/bigphoto/813/10381813_8_6.jpg",
        "https://ssl.cdn-redfin.com/photo/68/bigphoto/813/10381813_11_6.jpg",
        "https://ssl.cdn-redfin.com/photo/68/bigphoto/627/10373627_2.jpg",
        "https://ssl.cdn-redfin.com/photo/68/bigphoto/627/10373627_2_2.jpg",
        "https://ssl.cdn-redfin.com/photo/68/bigphoto/627/10373627_4_2.jpg",
        "https://ssl.cdn-redfin.com/photo/68/bigphoto/627/10373627_5_2.jpg",
        "https://ssl.cdn-redfin.com/photo/68/bigphoto/627/10373627_12_2.jpg",
        "https://ssl.cdn-redfin.com/photo/68/bigphoto/627/10373627_17_2.jpg"
    ];

    const scrollLeft = () => {
        setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : images.length - 1));
    };

    const scrollRight = () => {
        setCurrentIndex((prevIndex) => (prevIndex < images.length - 1 ? prevIndex + 1 : 0));
    };

    useEffect(() => {
        const interval = setInterval(() => {
            scrollRight();
        }, 2000);
        return () => {
            clearInterval(interval);
        };
    }, []);


    return (
        // large window
        isAboveSmallScreens ? (
            // BEFORE
            // <div className="relative w-full">
            //     {/* Carousel images */}
            //     <div className="relative flex items-center justify-center">
            //         {/* Carousel controls - prev item */}
            //         <button
            //             className="absolute left-0 z-10 flex items-center justify-center border-0 bg-none p-0 text-center text-black opacity-50 hover:text-black hover:no-underline hover:opacity-90 hover:outline-none focus:text-black focus:no-underline focus:opacity-90 focus:outline-none"
            //             type="button"
            //             onClick={handlePrev}
            //         >
            //             <svg
            //                 xmlns="http://www.w3.org/2000/svg"
            //                 fill="none"
            //                 viewBox="0 0 24 24"
            //                 strokeWidth="1.5"
            //                 stroke="currentColor"
            //                 className="h-6 w-6"
            //             >
            //                 <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
            //             </svg>
            //         </button>

            //         {/* Carousel images */}
            //         <div className="relative">
            //             {/* First item */}
            //             <div
            //                 className={`relative transition-opacity duration-300 ease-in-out ${activeIndex === 0 ? 'opacity-100' : 'opacity-0 hidden'
            //                     }`}
            //                 data-te-carousel-item
            //             >
            //                 <div className="lg:w-[1200px] lg:h-[500px] mx-auto sm:w-[500px] h-[400px]">
            //                     <img src={carousel1} className="block w-full h-full object-cover" alt="Wild Landscape" />
            //                 </div>
            //             </div>
            //             {/* Second item */}
            //             <div
            //                 className={`relative transition-opacity duration-300 ease-in-out ${activeIndex === 1 ? 'opacity-100' : 'opacity-0 hidden'
            //                     }`}
            //                 data-te-carousel-item
            //             >
            //                 <div className="lg:w-[1200px] lg:h-[500px] mx-auto sm:w-[500px] h-[400px]">
            //                     <img src={carousel2} className="block w-full h-full object-cover" alt="Camera" />
            //                 </div>
            //             </div>
            //             {/* Third item */}
            //             <div
            //                 className={`relative transition-opacity duration-300 ease-in-out ${activeIndex === 2 ? 'opacity-100' : 'opacity-0 hidden'
            //                     }`}
            //                 data-te-carousel-item
            //             >
            //                 <div className="lg:w-[1200px] lg:h-[500px] mx-auto sm:w-[500px] h-[400px]">
            //                     <img src={carousel3} className="block w-full h-full object-cover" alt="Exotic Fruits" />
            //                 </div>
            //             </div>
            //             {/* Fourth to Tenth items */}
            //             {[carousel4, carousel5, carousel6, carousel7, carousel8, carousel9, carousel10].map((image, index) => (
            //                 <div
            //                     key={index}
            //                     className={`relative transition-opacity duration-300 ease-in-out ${activeIndex === index + 3 ? 'opacity-100' : 'opacity-0 hidden'
            //                         }`}
            //                     data-te-carousel-item
            //                 >
            //                     <div className="lg:w-[1200px] lg:h-[500px] mx-auto sm:w-[500px] h-[400px]">
            //                         <img src={image} className="block w-full h-full object-cover" alt="Exotic Fruits" />
            //                     </div>
            //                 </div>
            //             ))}
            //         </div>
            //         {/* Add more items if needed */}

            //         {/* Carousel controls - next item */}
            //         <button
            //             className="absolute right-0 z-10 flex items-center justify-center border-0 bg-none p-0 text-center text-black opacity-50 hover:text-black hover:no-underline hover:opacity-90 hover:outline-none focus:text-black focus:no-underline focus:opacity-90 focus:outline-none"
            //             type="button"
            //             onClick={handleNext}
            //         >
            //             <svg
            //                 xmlns="http://www.w3.org/2000/svg"
            //                 fill="none"
            //                 viewBox="0 0 24 24"
            //                 strokeWidth="1.5"
            //                 stroke="currentColor"
            //                 className="h-6 w-6"
            //             >
            //                 <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
            //             </svg>
            //         </button>
            //     </div>

            //     {/* Carousel indicators */}
            //     <div className="absolute bottom-0 left-0 right-0 z-10 mx-auto mb-4 flex list-none justify-center p-0" data-te-carousel-indicators>
            //         {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((index) => (
            //             <button
            //                 key={index}
            //                 type="button"
            //                 className={`mx-1 box-content h-3 w-3 flex-initial cursor-pointer rounded-full border-0 bg-white opacity-50 hover:opacity-90 hover:outline-none focus:opacity-90 focus:outline-none ${activeIndex === index ? 'opacity-100' : ''
            //                     }`}
            //                 onClick={() => handleToggleClick(index)}
            //             ></button>
            //         ))}
            //     </div>
            // </div>

            // AFTER
            <div className="relative w-full">
                {/* Carousel images */}
                <div className="relative flex items-center justify-center">
                    {/* Carousel controls - prev item */}
                    <button
                        className="absolute left-0 z-10 flex items-center justify-center border-0 bg-none p-0 text-center text-black opacity-50 hover:text-black hover:no-underline hover:opacity-90 hover:outline-none focus:text-black focus:no-underline focus:opacity-90 focus:outline-none"
                        type="button"
                        onClick={handlePrev}
                    >
                        <FiChevronLeft className="h-20 w-20" />
                    </button>

                    {/* Carousel images */}
                    <div className="relative">
                        {/* First to Tenth items */}
                        {images.map((image, index) => (
                            <div
                                key={index}
                                className={`relative transition-opacity duration-300 ease-in-out ${activeIndex === index ? 'opacity-100' : 'opacity-0 hidden'
                                    }`}
                                data-te-carousel-item
                            >
                                <div className="w-full h-[400px]">
                                    <img src={image} className="block w-full h-full object-cover" alt={`Image ${index}`} />
                                </div>
                            </div>
                        ))}
                    </div>
                    {/* Add more items if needed */}

                    {/* Carousel controls - next item */}
                    <button
                        className="absolute right-0 z-10 flex items-center justify-center border-0 bg-none p-0 text-center text-black opacity-50 hover:text-black hover:no-underline hover:opacity-90 hover:outline-none focus:text-black focus:no-underline focus:opacity-90 focus:outline-none"
                        type="button"
                        onClick={handleNext}
                    >
                        <FiChevronRight className="h-20 w-20" />
                    </button>
                </div>

                {/* Carousel indicators */}
                <div className="absolute bottom-0 left-0 right-0 z-10 mx-auto mb-4 flex list-none justify-center p-0" data-te-carousel-indicators>
                    {images.map((_, index) => (
                        <button
                            key={index}
                            type="button"
                            className={`mx-1 box-content h-3 w-3 flex-initial cursor-pointer rounded-full border-0 bg-white opacity-50 hover:opacity-90 hover:outline-none focus:opacity-90 focus:outline-none ${activeIndex === index ? 'opacity-100' : ''
                                }`}
                            onClick={() => handleToggleClick(index)}
                        ></button>
                    ))}
                </div>
            </div>

            // small window
        ) : (
            <div className="relative">
                <br />
                <div
                    id="content"
                    className="carousel p-4 
            flex items-center
            justify-start overflow-x-auto
            relative gap-8
            scroll-smooth
            scrollbar-hide"
                >
                    <div>
                        <Card image={images[currentIndex]} />
                    </div>
                </div>
            </div>
        )
    );
};

export default Carousel;
