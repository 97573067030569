import React from 'react';
import abrar from '../assets/img/agents/abrar.jpeg';
import dad from '../assets/img/agents/dadA.jpeg';
import mom from '../assets/img/agents/momA.jpeg';
import shaheer from '../assets/img/agents/shaheer.jpeg';


const testimonialData = [
    {
        profilePic: shaheer,
        name: 'Shaheer Anwar',
        title: 'Research Assistant at Rush',
        description: 'Integer a est in massa aliquet scelerisque. Fusce eu metus euismod, accumsan nulla eu, cursus est.'
    },
    {
        profilePic: dad,
        name: 'Kashif Anwar',
        title: 'Director of Leasing | Company Name',
        description: 'Integer a est in massa aliquet scelerisque. Fusce eu metus euismod, accumsan nulla eu, cursus est.'
    },
    {
        profilePic: mom,
        name: 'Aisha Shakeel',
        title: 'Lead Interior Designer | Company Name',
        description: 'Integer a est in massa aliquet scelerisque. Fusce eu metus euismod, accumsan nulla eu, cursus est.'
    },,
    {
        profilePic: abrar,
        name: 'Abrar Hussain',
        title: 'Software Engineer | Company Name',
        description: 'Integer a est in massa aliquet scelerisque. Fusce eu metus euismod, accumsan nulla eu, cursus est.'
    }
    // Add more testimonial objects as needed
];

const TestimonialsPage = () => {
    const Testimonial = ({ profilePic, name, title, description }) => {
        return (
            <div className="bg-white shadow-md rounded-lg p-6 mb-4">
                <div className="flex items-center mb-4">
                    <img src={profilePic} alt="Profile" className="w-24 h-24 rounded-full" />
                    <div className="ml-4">
                        <h2 className="text-lg font-bold text-black text-left">{name}</h2>
                        <h3 className="text-gray-600 text-left">{title}</h3>
                    </div>
                </div>
                <i className="text-gray-600">"{description}"</i>
            </div>
        );
    };

    return (
        <div>
            <div className="container mx-auto px-4 py-8">
                <h1 className="text-[#126CB3] font-bold text-[35px] text-center">Let us prove it you! </h1>
                <h3 className="text-[18px] font-bold my-font mb-6 text-gray-700 text-center">See what others have to say</h3>
                <hr className='mb-8' style={{ marginLeft: '25%', marginRight: '25%', borderColor: 'darkgrey' }} />
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
                    {testimonialData.map((testimonial, index) => (
                        <Testimonial
                            key={index}
                            profilePic={testimonial.profilePic}
                            name={testimonial.name}
                            title={testimonial.title}
                            description={testimonial.description}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TestimonialsPage;
