import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import connect from '../assets/img/connect.svg';
import keys from '../assets/img/keys.svg';
import sofa from '../assets/img/sofa.svg';
import suitcase from '../assets/img/suitcase.svg';
import chat from '../assets/img/chat.svg';
import canvas from '../assets/img/canvas.svg'
import magnifyingGlass from '../assets/img/magnifyingGlass.svg'
import gear from '../assets/img/gear.svg'
import InteriorBanner from './InteriorBanner';

const TimelineElement = ({ icon, image, title, description }) => {
    return (
        <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: '#0B1223', color: '#fff' }}
            contentArrowStyle={{ borderRight: '7px solid #0B1223' }}
            iconStyle={{ background: '#0B1223', color: '#fff' }}
            icon={icon}
        >
            <img src={image} className="h-[75px]" alt={title} />
            <h3 className="text-[24px] text-left mt-5">{title}</h3>
            <p className="text-left mt-3">{description}</p>
        </VerticalTimelineElement>
    );
};

const houseServiceData = [
    {
        icon: <i className="fas fa-briefcase"></i>,
        image: connect,
        title: 'Connect with our team',
        description:
            'Upon connecting with our team, we perform market research on competitors and your area to evaluate the potential of the property.',
    },
    {
        icon: <i className="fas fa-briefcase"></i>,
        image: keys,
        title: 'Lease with us',
        description: 'Upon agreement of terms describing the contract details, we will sign a leasing agreement and begin our partnership with you.',
    },
    {
        icon: <i className="fas fa-graduation-cap"></i>,
        image: sofa,
        title: 'We design & furnish your space',
        description:
            'Our design team equips your unit with premium furniture, bedding, kitchenware & tech gadgets like smart TVs & bluetooth speakers.',
    },
    {
        icon: <i className="fas fa-briefcase"></i>,
        image: suitcase,
        title: 'We fill the units with vetted guests',
        description:
            'Our committed team will secure tenants for your unit, tapping into latent market demand for adaptable rentals, fully furnished accommodations, and corporate travel reservations.',
    },
    {
        icon: <i className="fas fa-briefcase"></i>,
        image: chat,
        title: 'We service & support the tenants',
        description:
            'From the meticulous provision of cleaning and maintenance services to the attentive handling of guest inquiries and assistance, we proficiently manage all tenant requisitions, affording you the luxury of a hands-off approach as you relax and collect your rental income.',
    },
];

const interiorServiceData = [
    {
        icon: <i className="fas fa-briefcase"></i>,
        image: connect,
        title: 'Consultation with our team',
        description:
            'Upon establishing a consultation with our team, we will provide you with a tailored quote aligning with the specific requirements of your project. Upon approval and endorsement of the contract, we propose a scheduled on-site design consultation to comprehensively review your project. This consultation will adeptly consider the integration of architectural details and the inclusion of any desired existing furnishings.',
    },
    {
        icon: <i className="fas fa-briefcase"></i>,
        image: canvas,
        title: 'Design Selection',
        description: 'At SKA, we firmly uphold the notion that your residences bear a distinct and intimate significance. Hence, we approach this endeavor as an intensely collaborative journey, wherein our adept designers work in tandem with our esteemed clients. Our proficient team will unveil a comprehensive design package encompassing an array of furniture selections, affording clients the privilege of choice. In instances of remodeling projects, we extend a curated array of diverse materials and finishes, enabling clients to make selections in alignment with their vision.',
    },
    {
        icon: <i className="fas fa-graduation-cap"></i>,
        image: magnifyingGlass,
        title: 'Design Visualization',
        description:
            'Furthermore, our commitment to excellence entails the provision of elevations and 3D photorealistic renderings, a meticulous offering that allows you to envision your space in vivid detail prior to the commencement of our work. This approach allows us to ensure an optimum utilization of both time and financial resources for all parties involved.',
    },
    {
        icon: <i className="fas fa-briefcase"></i>,
        image: gear,
        title: 'Design Installation',
        description:
            'A designated installation day will be coordinated to bring life into your spaces. Our profound satisfaction lies in imparting distinctive and unparalleled finishing touches to each of your projects, rendering them truly exceptional and unparalleled. This distinctive approach distinctly differentiates us, elevating your project to a level that surpasses the ordinary landscape. We steadfastly adhere to the belief in cultivating an ambience of collected and inhabited aesthetics within our designs, a sentiment we wholeheartedly extend to our esteemed clients.',
    },
    // {
    //     icon: <i className="fas fa-briefcase"></i>,
    //     image: chat,
    //     title: 'We service & support the tenants',
    //     description:
    //         'From professional cleaning & maintenance to guest support & service, we handle all tenant requests while you sit back & collect rent.',
    // },
];

const ServiceTimeLine = () => {
    return (
        <div>
            {/* house service timeline */}
            <div className='bg-[#0B1223] text-black py-20 flex flex-col items-center justify-center'>
                <div className='text-[40px] mb-3 my-font text-white'>Leasing Process</div>
                <p className="text-[15px] font-bold my-font mb-6 text-white text-center">For you to become a partner and unlock maximal rental revenue </p>
                <VerticalTimeline>
                    {houseServiceData.map((item, index) => (
                        <TimelineElement
                            key={index}
                            icon={item.icon}
                            image={item.image}
                            title={item.title}
                            description={item.description}
                        />
                    ))}
                </VerticalTimeline>
            </div>


            <div className='bg-[white] py-10'></div>

            <div className='mb-20'>
                <InteriorBanner />
            </div>

            {/* interior service timeline */}
            <div className='bg-[#0B1223] text-black py-20 flex flex-col items-center justify-center'>
                <div className='text-[40px] mb-3 my-font text-white text-center'>Professional Interior Design Consultancy Process</div>
                <p className="text-[15px] font-bold my-font mb-6 text-white text-center">For you to become a partner and tap into the upside of your asset</p>
                <VerticalTimeline>
                    {interiorServiceData.map((item, index) => (
                        <TimelineElement
                            key={index}
                            icon={item.icon}
                            image={item.image}
                            title={item.title}
                            description={item.description}
                        />
                    ))}
                </VerticalTimeline>
            </div>
        </div>
    );
};

export default ServiceTimeLine;
