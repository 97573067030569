import React from 'react'
import keyIcon from '../assets/img/keyIcon.svg'
import skyscraperIcon from '../assets/img/skyscraperIcon.svg'

const ServiceBenefits = () => {
    return (
        <div className='container mx-auto px-5 mt-3 mb-10'>
            <h1 className='my-font text-[2rem] mb-3 text-gray-400'><span className='font-bold text-[35px] text-[#126CB3] justify-center items-center flex'>Our Benefits</span> </h1>
            {/* <p className="text-lg my-3 my-font text-gray-800 mb-10">Rental revenue upside by transforming your empty space into a fully-equipped home</p> */}
            <div className="flex flex-col md:flex-row">
                {/* Left side container */}
                <div className="md:w-1/2 p-8 md:rounded-tr-90px bg-[#F3F7FB] rounded-lg rounded-tr-[90px] mb-3 md:mb-0">
                    <div>
                        <div className='flex'>
                            <img src={keyIcon} className='h-[40px] mr-3' />
                            <div className="mb-7">
                                <h3 className="text-black text-2xl font-bold my-font text-left">Benefits to Property Managers</h3>
                            </div>
                        </div>
                        <hr className="border-gray-300 mb-7" />
                        <div>
                            <p className="text-black my-font text-lg font-bold mb-7 text-left">Eliminate Extraneous Expenditures + Accelerated Lease-up</p>
                            <p className="text-black my-font mb-7 text-left">
                                Significant rental income and occupancy upside can be generated by converting your unit into a move in ready home through our our expert staging and state-of-the-art automation protocols. A collaborative association with us opens the door to a multitude of cost savings, encompassing but not limited to: listing fees and realtor commissions, sidestepping exhaustive cleaning processes, mitigating vacancies and tenant turnovers, insurance premiums, and property upkeep.
                            </p>
                        </div>
                        <div>
                            <p className="text-black my-font text-lg font-bold mb-7 text-left">Property Upkeep; Time to Sell, No Problem!</p>
                            <p className="text-black my-font mb-7 text-left">
                                Unlike standard tenants who bring maintenance havoc come move out date, it is essential for the profitability of our business to keep your units as advertised and in A PLUS conditions at all times (as negative reputation significantly impacts revenue). Your unit will undergo cleaning multiple times throughout the week, ensuring it remains in impeccable conditions at all times, including a time where you may want to list your property for sale.
                            </p>
                        </div>
                    </div>
                    <div>
                        <div className="mb-7">
                            <h3 className="text-black my-font text-lg font-bold mb-7 text-left">Premium, Vetted-Guest Client Network</h3>
                        </div>
                        <p className="text-black my-font mb-7 text-left">
                            Our esteemed clientele base encompasses a diverse spectrum, ranging from corporate travelers seeking accommodations to private renters in pursuit of a comfortable abode. Every member of this clientele is subjected to meticulous screening by our proficient team, through a comprehensive array of tools and rigorous background assessments.
                        </p>
                    </div>
                </div>

                {/* Right side container */}
                <div className="md:w-1/2 p-8 md:rounded-tr-90px bg-[#F4F8F1] rounded-lg rounded-tr-[90px] md:ml-6 mb-3 md:mb-0">
                    <div>
                        <div className='flex'>
                            <img src={skyscraperIcon} className='h-[40px] mr-3' />
                            <div className="mb-7">
                                <h3 className="text-black text-2xl font-bold my-font text-left">What makes SKA different?</h3>
                            </div>
                        </div>
                        <hr className="border-gray-300 mb-7" />
                        <div>
                            <p className="text-black my-font text-lg font-bold mb-7 text-left">Verified Operating Procedure</p>
                            <p className="text-black my-font mb-7 text-left">
                                From laundry optimization to revenue management, every aspect of the business incorporates a verified operating procedure (also utilized by our industry expert advisor, who happens to be one of the largest operators in the USA with over 300 units across 4 states). Significant demand drivers include our world class digital marketing capabilities, sophisticated market and amenity research, intelligent pricing/search algorithm, and exposure in the business.
                            </p>
                        </div>
                        <div>
                            <p className="text-black my-font text-lg font-bold mb-7 text-left">100% Protected Investment</p>
                            <p className="text-black my-font mb-7 text-left">
                                We ensure the security of your residence through the provision of cutting-edge protection and liability coverage for every reservation, coupled with the presence of a dedicated property manager nearby. Moreover, we enhance protective measures by incorporating smart locks, ring door, smart power outlets for appliances, smoke detectors, and noise detectors, affording supplementary layers of remote oversight for your property.
                            </p>
                        </div>
                    </div>
                    <div>
                        <div className="mb-7">
                            <h3 className="text-black my-font text-lg font-bold mb-7 text-left">Work with a Reliable Family Business</h3>
                        </div>
                        <p className="text-black my-font mb-7 text-left">
                            Our core team comprises of exclusively in-house experts, encompassing pivotal roles such as the lead interior designer, lead software engineer, property manager, chief technology officer, and director of leasing. This strategic alignment serves as a catalyst for profit optimization by profoundly benefiting both parties.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceBenefits