import React from 'react'

const Card = ({ image }) => {
  return (
    <div className="card w-full h-full rounded-lg shadow-lg border mt-3">
      <div className="top">
        <img
          className="w-full h-full object-cover"
          src={image}
          alt="img"
        />
      </div>
    </div>
  );
};

export default Card