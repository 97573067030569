import React, { useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import useMediaQuery from '../utils/useMediaQuery';
import MenuIcon from '../assets/menu-icon.svg';
import CloseIcon from '../assets/close-icon.svg';
import ourLogo from '../assets/img/ourLogo.jpeg'

const Navbar = () => {
  const aboutRef = useRef(null);
  const location = useLocation();
  const [isMenuToggled, setIsMenuToggled] = useState(false);
  const isAboveSmallScreens = useMediaQuery('(min-width:768px)');

  const handleLinkClick = () => {
    if (!isAboveSmallScreens) {
      setIsMenuToggled(false);
    }
  };

  return (
    <div>
      <nav className="flex items-center justify-between px-6  bg-white shadow-md z-10">
        <div className="flex items-center">
          <a href="/" className='flex'>
            <img src={ourLogo} alt="ourlogo" height={75} width={75} />
          </a>
        </div>
          {/* large window */}
        {isAboveSmallScreens ? (
          <div className="flex items-center space-x-6">
            <Link
              to="/portfolio"
              className={`hover:text-[#126CB3] ${location.pathname === '/portfolio' ? 'font-bold text-[#126CB3]' : 'normal'}`}
            >
              Portfolio
            </Link>
            <Link
              to="/services"
              className={`hover:text-[#126CB3] ${location.pathname === '/services' ? 'font-bold text-[#126CB3]' : 'normal'}`}
            >
              Services
            </Link>
            <Link
              to="/about"
              className={`hover:text-[#126CB3] ${location.pathname === '/about' ? 'font-bold text-[#126CB3]' : 'normal'}`}
            >
              About
            </Link>
            {/* <Link
                to="/testimonials"
                onClick={handleLinkClick}
                className={`hover:text-[#126CB3] my-font ${location.pathname === '/testimonials' ? 'font-bold text-[#126CB3]' : 'normal'}`}
              >
                <h1>Testimonials</h1>
              </Link> */}
            <Link
              to="/contact"
              className={`nav-link nav-link-contact ${location.pathname === '/contact' ? 'nav-link-active' : ''}`}
            >
              Contact
            </Link>
          </div>
          // small window
        ) : (
          <button
            className="rounded-full bg-white p-2 z-10"
            onClick={() => setIsMenuToggled(!isMenuToggled)}
          >
            <img alt="menu-icon" src={MenuIcon} />
          </button>
        )}
        {!isAboveSmallScreens && isMenuToggled && (
          <div className="fixed top-0 right-0 h-full bg-black w-full flex justify-center items-center z-50">
            {/* CLOSE ICON */}
            <div className="flex justify-end p-12">
              <button onClick={() => setIsMenuToggled(!isMenuToggled)}>
                <img alt="close-icon" src={CloseIcon} />
              </button>
            </div>
            {/* Menu Items */}
            <div className="flex flex-col gap-10 text-2xl text-white">
            <Link
                to="/"
                onClick={handleLinkClick}
                className={`hover:text-[#126CB3] my-font `}
              >
                <h1>Home</h1>
              </Link>
              <Link
                to="/portfolio"
                onClick={handleLinkClick}
                className={`hover:text-[#126CB3] my-font ${location.pathname === '/portfolio' ? 'font-bold' : 'normal'}`}
              >
                <h1>Portfolio</h1>
              </Link>
              <Link
                to="/services"
                onClick={handleLinkClick}
                className={`hover:text-[#126CB3] my-font ${location.pathname === '/services' ? 'font-bold' : 'normal'}`}
              >
                <h1>Services</h1>
              </Link>
              <Link
                to="/about"
                onClick={handleLinkClick}
                className={`hover:text-[#126CB3] my-font ${location.pathname === '/about' ? 'font-bold' : 'normal'}`}
              >
                <h1>About</h1>
              </Link>
              {/* <Link
                to="/testimonials"
                onClick={handleLinkClick}
                className={`hover:text-[#126CB3] my-font ${location.pathname === '/testimonials' ? 'font-bold' : 'normal'}`}
              >
                <h1>Testimonials</h1>
              </Link> */}
              <Link
                to="/contact"
                onClick={handleLinkClick}
                className={`mt-6 py-2 px-4 border border-[#126CB3] text-[#126CB3] hover:bg-[#126CB3] hover:text-white z-10 my-font ${location.pathname === '/contact' ? 'font-bold' : 'normal'}`}
              >
                <h1>Contact</h1>
              </Link>
            </div>
          </div>
        )}
      </nav>
    </div>
  );
};

export default Navbar;
