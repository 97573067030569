import React from 'react';
import { Link } from 'react-router-dom';
import Carousel from '../components/Carousel';
import house1 from '../assets/img/houses/house1lg.png';
import house2 from '../assets/img/houses/house2lg.png';
import house3 from '../assets/img/houses/house3lg.png';
import house4 from '../assets/img/houses/house4lg.png';
import house5 from '../assets/img/houses/house5lg.png';
import house6 from '../assets/img/houses/house6lg.png';
import house7 from '../assets/img/houses/house7lg.png';
import house8 from '../assets/img/houses/house8lg.png';
import house10 from '../assets/img/houses/house10lg.png';

const rehabbedProperties = [
  {
    address: '403 Woods Rd, Des Plaines, IL 60016',
    rooms: '7 rooms',
    image: house1,
    link: 'https://www.redfin.com/IL/Des-Plaines/403-Woods-Rd-60016/home/13666150',
    // link:'/403-woods-road'
  },
  {
    address: '7212 Beckwith Rd, Morton Grove, IL 60053',
    rooms: '4 rooms',
    image: house2,
    link: 'https://www.redfin.com/IL/Morton-Grove/7212-Beckwith-Rd-60053/home/13676781',
    // link:'/7212-beckwith-road'
  },
  {
    address: '2909 Bolingbroke Dr, Troy, MI 48084',
    rooms: '3 rooms',
    image: house3,
    link: 'https://www.redfin.com/MI/Troy/2909-Bolingbroke-Dr-48084/home/93117114',
    // link:'/2909-bolingbroke-drive'
  },
  {
    address: '2160 Woodview Ln, Park Ridge, IL 60068',
    rooms: '5 rooms',
    image: house4,
    link: 'https://www.redfin.com/IL/Park-Ridge/2160-Woodview-Ln-60068/home/13668440',
    // link:'/2160-woodview-ln'
  },
  {
    address: '2420 Rankin Ave, Windsor, ON N9E 3B6',
    rooms: '3 rooms',
    image: house5,
    link: 'https://www.zillow.com/homedetails/2420-Rankin-Ave-Norfolk-VA-23518/79190103_zpid/',
    // link:'/2420-rankin-ave'
  },
];

const developedProperties = [
  {
    address: '403 Woods Rd Des Plaines, IL 60016',
    rooms: '7 rooms',
    image: house10,
    link: 'https://www.redfin.com/IL/Des-Plaines/403-Woods-Rd-60016/home/13666150',
    // link:'/403-woods-road-past',
    description:
      '❖ Our management acquired this property after it had caught on fire. We then redeveloped it and professionally interior designed/rehabbed the property under the direction of our lead interior designer to bring life to this priceless abode.',
  },
  {
    address: '54204 State Route 2, Gold Bar, WA, 98251',
    rooms: '4.54 acres',
    image: house6,
    link: 'https://www.zillow.com/homedetails/54204-State-Route-2-Gold-Bar-WA-98251/92410232_zpid/',
    // link:'/54204-state-route',
    description: '❖ This property is under construction to provide better housing for you!',
  },
];

const vacationRentals = [
  {
    address: '2160 Woodview Ln, Park Ridge, IL 60068',
    rooms: '5 rooms',
    image: house4,
    link: 'https://www.redfin.com/IL/Park-Ridge/2160-Woodview-Ln-60068/home/13668440',
    // link:'/2160-woodview-ln-furnished',
    description: '❖ This property has been rehabbed and placed on the short term rental market.',
  },
];

const PropertyCard = ({ property }) => {
  const { address, rooms, image, link, description } = property;
  return (
    <a href={link}>
      <div className="bg-white rounded-lg p-5 text-center border border-gray-300 hover:bg-gray-100">
        <img src={image} alt="Home" className="mx-auto mb-3" style={{ height: '195px', width: '475px' }} />
        <h3 className="font-bold text-black my-font">{address}</h3>
        <p className="text-black my-font text-xs">{rooms}</p>
        {description && <p className="text-black my-font text-xs text-left mt-3">{description}</p>}
      </div>
    </a>
  );
};

const Portfolio = () => {
  return (
    <div className="container mx-auto px-5 mt-3 mb-10">
      <br />
      <Carousel />
      <br /><br />
      <h1 className="my-font text-[2rem] mb-3 text-gray-400 justify-center items-center flex">
        <div className="text-center">
          <span className="font-bold text-[35px] text-[#126CB3]">Our Rehabbed Properties</span>
        </div>

      </h1>
      <div className="sm:grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {rehabbedProperties.map((property, index) => (
          <PropertyCard key={index} property={property} />
        ))}
      </div>
      <br />

      <h1 className="my-font text-[2rem] mb-3 text-gray-400 mt-20 justify-center items-center flex">
        {' '}
        <div className="text-center">
          <span className="text-[#126CB3] font-bold text-[35px]">Past Developed/Under Development</span>
        </div>
      </h1>
      <div className="sm:grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {developedProperties.map((property, index) => (
          <PropertyCard key={index} property={property} />
        ))}
      </div>

      <h1 className="my-font text-[2rem] mb-3 text-gray-400 mt-20 justify-center items-center flex">
        {' '}
        <div className="text-center">
          <span className="text-[#126CB3] font-bold text-[35px]">Our Furnished Flexible Rentals</span>
        </div>
      </h1>
      <div className="sm:grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {vacationRentals.map((property, index) => (
          <PropertyCard key={index} property={property} />
        ))}
      </div>
      <br />
    </div>
  );
};

export default Portfolio;
