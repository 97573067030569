import React from 'react';
import ServiceBanner from '../components/ServiceBanner';
import ServiceBenefits from '../components/ServiceBenefits';
import ServiceTimeLine from '../components/ServiceTimeLine';
import ServiceOperations from '../components/ServiceOperations';
import LetsTalk from '../components/LetsTalk'

const Services = () => {
    return (
        <div>
            <div className='mb-20'>
                <ServiceBanner />
            </div>
            <div className='mb-20'>
                <ServiceBenefits />
            </div>
            <div className='mb-20'>
                <ServiceTimeLine />
            </div>
            <div className='mb-20'>
                <ServiceOperations />
            </div>
            <hr />
            <div className='bg-[#0B1223]'>
                <div className='p-5'>
                    <LetsTalk />
                </div>
            </div>

        </div>
    );
};

export default Services;
