import React from 'react';
import { Link } from 'react-router-dom';
import Carousel from '../components/Carousel';
import shaheer from '../assets/img/agents/shaheer.jpeg';
import shaheerA from '../assets/img/agents/shaheerA.jpeg';
import dad from '../assets/img/agents/dadA.jpeg';
import mom from '../assets/img/agents/momA.jpeg';
import abrar from '../assets/img/agents/abrar.jpeg';

const teamMembers = [
    {
        name: 'Shaheer Anwar',
        role: 'Chief Technological Officer',
        image: shaheerA,
        description: 'Role: Streamlines all aspects of the business including customer relationship management, strategic staff recruitment, and prioritizing landlord relationships/objectives.',
    },
    {
        name: 'Kashif Anwar',
        role: 'Director of Leasing',
        image: dad,
        description: 'Role: Negotiates and oversees all contracts.',
    },
    {
        name: 'Aisha Shakeel',
        role: 'Lead Interior Designer',
        image: mom,
        description: 'Role: Stages all units utilizing 3D photorealistic renderings/elevations, current trends, and 8 years of industry experience.',
    },
    {
        name: 'Abrar Hussain',
        role: 'Lead Software Engineer & Data Scientist',
        image: abrar,
        description: 'Role: Updates website and utilizes software engineering and data science for continuous analysis on how we can optimize revenue and expand in different markets.',
    },
];

const TeamMemberCard = ({ member }) => {
    const { name, role, image, description } = member;
    return (
        <div className="bg-white rounded-lg shadow-md p-4 mb-4">
            <div className="flex flex-col md:flex-row items-center md:items-start">
                <img className="h-32 w-32 object-cover md:mr-4 mb-3 md:mb-0" src={image} alt={name} />
                <div className="text-center md:text-left">
                    <h2 className="text-lg font-bold">{name}</h2>
                    <p className="text-gray-600 mb-2">{role}</p>
                    <p className="text-gray-600 text-sm italic">{description}</p>
                </div>
            </div>
        </div>
    );
};

const About = () => {
    return (
        <div style={{ backgroundColor: '' }}>
            <div className="container mx-auto ">
                {/* Reasons to Partner with Us */}
                {/* <div className="flex flex-col md:flex-row">
                    <div className="md:w-1/2 p-8 md:py-0">
                        <h1 className="text-[#126CB3] font-bold text-[35px] text-left">Reasons to Partner with Us</h1>
                        <h4 className="text-[24px] font-bold my-font mb-6 text-gray-700 text-left">Eliminate the costs of:</h4>
                        <ul className="list-none mt-3 text-gray-700 font-bodoni-moda text-left">
                            <li className="mb-2">❖ Listing fees</li>
                            <li className="mb-2">❖ Realtor fees</li>
                            <li className="mb-2">❖ Deep cleaning</li>
                            <li className="mb-2">❖ Vacancy</li>
                            <li className="mb-2">❖ Tenant turnover</li>
                            <li className="mb-2">❖ Professional staging/automation</li>
                        </ul>
                        <div className="flex justify-start items-start mt-8">
                            <Link
                                to="/services"
                                className="py-2 px-4 border border-[#126CB3] text-[#126CB3] hover:bg-[#126CB3] hover:text-white my-font"
                            >
                                View all services
                            </Link>
                        </div>
                    </div>
                    <div className="md:w-1/2 flex items-start justify-center">
                        <img
                            className=""
                            style={{ borderRadius: '8px', marginTop: '0' }}
                            src="https://www.honeyandlime.co/wp-content/uploads/2017/06/beautiful-suburban-home-at-dusk.jpeg"
                            alt="About Me"
                        />
                    </div>
                </div> */}
                <br /><br />
                {/* <div className="flex flex-col md:flex-row mt-10">
                    <div className="md:w-1/2 flex items-start justify-center">
                        <img
                            className=""
                            style={{ borderRadius: '8px', marginTop: '0' }}
                            src="https://media.licdn.com/dms/image/C4D16AQEf2FWfreav4g/profile-displaybackgroundimage-shrink_350_1400/0/1618063992506?e=1695254400&v=beta&t=1ctQ4yVbPL38chjRzss3wuGdkMQJIdM2cDnAYpgsIuA"
                            alt="Motivational view"
                        />
                    </div>
                    <div className="md:w-1/2 p-8 md:py-0">
                        <h2 className="text-3xl font-bold font-bodoni-moda text-[#126CB3]">Why Us</h2>
                        <ul className="list-none text-left mt-3 text-gray-700 font-bodoni-moda">
                            <li className="mb-2">
                                ❖ It is in our best interest to prioritize you and your property, so that we may form a long term relationship that benefits both parties.
                            </li>
                            <li className="mb-10">
                                ❖ All of our staff is in house, which includes the lead interior designer, property managers, chief technology officer, director of leasing, lead software engineer and data scientist, a contractor for automation set up, and cleaners. This assists in optimizing profits for both parties.
                            </li>
                            <Link
                                to="/portfolio"
                                className="py-2 px-4 border border-[#126CB3] text-[#126CB3] hover:bg-[#126CB3] hover:text-white my-font"
                            >
                                View portfolio
                            </Link>
                        </ul>
                    </div>
                </div> */}
                <br /><br />
                {/* Centered Content */}
                <div className="flex flex-col items-center">
                    <div className="text-center">
                        <h2 className="text-3xl font-bold font-bodoni-moda text-[#126CB3] mb-4">Roles of Our Key Personnel</h2>
                        <p className="mb-6 my-font">
                            Our team comprises of pivotal individuals who play essential roles in the seamless functioning and success of our operations. These key personnel are instrumental in orchestrating various facets of our business to ensure optimal performance and achievement of our organizational objectives.
                        </p>
                    </div>
                    <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-2 mb-6">
                        {teamMembers.map((member, index) => (
                            <TeamMemberCard key={index} member={member} />
                        ))}
                    </div>
                    <br />
                    <Link
                        to="/contact"
                        className="py-2 px-4 border border-[#126CB3] text-[#126CB3] hover:bg-[#126CB3] hover:text-white my-font mb-8"
                    >
                        Contact Us
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default About;
