import React from 'react';
import trendIcon from '../assets/img/trendIcon.svg';
import moneyIcon from '../assets/img/moneyIcon.svg';
import serviceIcon from '../assets/img/serviceIcon.svg';
import homeIcon from '../assets/img/homeIcon.svg'
import { Link } from 'react-router-dom';
import AllServicesButton from '../components/AllServicesButton'

const WhyBest = () => {
    return (
        <div className="container mx-auto px-5 mt-3 mb-10">
            <h1 className="text-3xl text-[#126CB3] my-font mb-3 justify-center items-center flex">Why We Are the Best</h1>
            <div className="flex flex-wrap justify-center md:justify-between mb-6">
                {/* Image 1 */}
                <div className="w-full md:w-1/2 lg:w-1/4 p-3 hover:opacity-75 flex flex-col justify-center items-center">
                    <div className="hover:opacity-75">
                        <img src={homeIcon} alt="Trend Icon" className="mx-auto" />
                    </div>
                    <h3 className="text-lg font-bold my-3 text-center">Year-round Concierge and Property Services</h3>
                    <p className="text-center">It is essential for the profitability of our business to ensure all operations are running smoothly and to keep your unit as advertised and in A+ condition at all times (as negative reputation significantly impacts revenue).</p>
                </div>


                {/* Image 2 */}
                <div className="w-full md:w-1/2 lg:w-1/4 p-3 hover:opacity-75 flex flex-col justify-center items-center">
                    <div className="hover:opacity-75">
                        <img src={trendIcon} alt="Money Icon" className="mx-auto" />
                    </div>
                    <h3 className="text-lg font-bold my-3 text-center">Enhance Revenue with our Dynamic Pricing Software</h3>
                    <p className="text-center">This takes into account day of the week, month of the year, nearby events, occupancy rate of competitors, supply, demand, etc to generate the best suited rate for your property so that optimal revenue is generated.</p>
                </div>


                {/* Image 3 */}
                <div className="w-full md:w-1/2 lg:w-1/4 p-3 hover:opacity-75 flex flex-col justify-center items-center">
                    <div className="hover:opacity-75">
                        <img src={serviceIcon} alt="Service Icon" className="mx-auto" />
                    </div>
                    <h3 className="text-lg font-bold my-3 text-center">Professional staging & HDR Photography</h3>
                    <p className="text-center">Every aspect of your property is meticulously staged by our lead interior designer (with eight years of industry experience) to curate maximal revenue generation and enhance visitor experience.</p>
                </div>


                {/* Image 4 */}
                <div className="w-full md:w-1/2 lg:w-1/4 p-3 hover:opacity-75 flex flex-col justify-center items-center">
                    <div className="hover:opacity-75">
                        <img src={moneyIcon} alt="Home Icon" className="mx-auto" />
                    </div>
                    <h3 className="text-lg font-bold my-3 text-center">Work with a Reliable Family Business You Trust</h3>
                    <p className="text-center">All of our team is in house, which assists in optimizing profits for both parties and generating long term relationships. We are dedicated to providing top-notch services and ensuring your satisfaction every step.</p>
                </div>

            </div>
            <div className='justify-center items-center flex'>
                <AllServicesButton />
            </div>
        </div>
    );
};

export default WhyBest;
