import React from 'react';
import Bannner from '../components/Banner'
import HomeProperties from '../components/HomeProperties';
import HomeServices from '../components/HomeServices';
import LineGradient from '../components/LineGradient';
import WhyBest from '../components/WhyBest';
import LetsTalk from '../components/LetsTalk';
import LineBreaker from '../components/LineBreaker';

const Home = () => {
  return (
    <div className="">
      <Bannner/>
      <HomeServices/>
      <LineBreaker/>
      <HomeProperties/>
      <LineBreaker/>
      <LineBreaker/>
      <WhyBest/>
      <LineBreaker/>
      <LetsTalk/>
    </div>
  );
}

export default Home;
