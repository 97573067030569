import React from 'react'
import { Link } from 'react-router-dom'

const AllPropertiesButton = () => {
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <Link
            to="/portfolio"
            className="py-2 px-4 border border-[#126CB3] text-[#126CB3] hover:bg-[#126CB3] hover:text-white my-font"
            onClick={scrollToTop}
        >
            View all flexible rentals
        </Link>
    )
}

export default AllPropertiesButton