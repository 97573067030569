import React from "react";
import { useForm } from "react-hook-form";
import { motion } from "framer-motion";

const Contact = () => {
    const {
        register,
        trigger,
        formState: { errors },
    } = useForm();

    const onSubmit = async (e) => {
        console.log("~ e", e);
        const isValid = await trigger();
        if (!isValid) {
            e.preventDefault();
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen">
            <section id="contact" className="contact py-10">
                <div className="container mx-auto">
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ duration: 0.5 }}
                        variants={{
                            hidden: { opacity: 0, x: 50 },
                            visible: { opacity: 1, x: 0 },
                        }}
                    >
                        {/* Add your headings content here */}
                    </motion.div>

                    <h1 className="text-3xl text-gray-400 my-font mb-3"><span className='text-[#126CB3] font-bold text-[35px] justify-center items-center flex'>Contact Us Now</span> </h1>

                    <p className="font-playfair text-lg text-center">
                        <p className="text-lg my-6 my-font text-gray-800 ">
                            Whether you're looking to buy or rent your dream property, we're here to assist you every step of the way. 
                            <br/>
                            Reach out to us today and let us address your property needs.
                        </p>
                    </p>

                    <div className="flex flex-wrap justify-center mt-10">
                        <motion.form
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.5 }}
                            transition={{ delay: 0.2, duration: 0.5 }}
                            variants={{
                                hidden: { opacity: 0, y: 50 },
                                visible: { opacity: 1, y: 0 },
                            }}
                            className="w-full max-w-lg"
                            onSubmit={onSubmit}
                            action="https://formsubmit.co/support@skarealtypros.com"
                            method="POST"
                        >
                            <div className="mb-4 w-full">
                                <input
                                    className="w-full bg-white font-semibold placeholder-gray p-3 rounded-lg border border-gray-300"
                                    type="text"
                                    placeholder="First and last name"
                                    {...register("name", {
                                        required: true,
                                        maxLength: 100,
                                    })}
                                />
                                {errors.name && (
                                    <p className="text-red-500 mt-1 text-left">
                                        {errors.name.type === "required" && "This field is required."}
                                        {errors.name.type === "maxLength" && "Max length is 100 characters."}
                                    </p>
                                )}
                            </div>

                            <div className="mb-4 w-full">
                                <input
                                    className="w-full bg-white font-semibold placeholder-gray p-3 rounded-lg border border-gray-300"
                                    type="text"
                                    placeholder="Email address"
                                    {...register("email", {
                                        required: true,
                                        pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    })}
                                />
                                {errors.email && (
                                    <p className="text-red-500 mt-1 text-left">
                                        {errors.email.type === "required" && "This field is required."}
                                        {errors.email.type === "pattern" && "Invalid email address."}
                                    </p>
                                )}
                            </div>

                            <div className="mb-4 w-full">
                                <input
                                    className="w-full bg-white font-semibold placeholder-gray p-3 rounded-lg border border-gray-300"
                                    type="number"
                                    placeholder="Phone number"
                                    {...register("phone", {
                                        required: true,
                                    })}
                                />
                                {errors.phone && (
                                    <p className="text-red-500 mt-1 text-left">
                                        {errors.phone.type === "required" && "This field is required."}
                                    </p>
                                )}
                            </div>

                            <div className="mb-4 w-full">
                                <textarea
                                    className="w-full bg-white font-semibold placeholder-gray p-3 rounded-lg border border-gray-300"
                                    name="message"
                                    placeholder="What would you like to talk about?"
                                    rows="4"
                                    cols="50"
                                    {...register("message", {
                                        required: true,
                                        maxLength: 2000,
                                    })}
                                />
                                {errors.message && (
                                    <p className="text-red-500 mt-1 text-left">
                                        {errors.message.type === "required" && "This field is required."}
                                        {errors.message.type === "maxLength" && "Max length is 2000 characters."}
                                    </p>
                                )}
                            </div>

                            <div className="mb-4 w-full flex justify-between">
                                <select
                                    {...register("timeframe", { required: true })}
                                    className="w-5/12 bg-white font-semibold placeholder-gray p-3 rounded-lg border border-gray-300"
                                >
                                    <option value="" disabled selected>
                                        Select Timeframe
                                    </option>
                                    <option value="1-3 months">1-3 months</option>
                                    <option value="3-6 months">3-6 months</option>
                                    <option value="6+ months">6+ months</option>
                                </select>
                                {errors.timeframe && (
                                    <p className="text-red-500 mt-1 text-left">This field is required.</p>
                                )}

                                <select
                                    {...register("budget", { required: true })}
                                    className="w-5/12 bg-white font-semibold placeholder-gray p-3 rounded-lg border border-gray-300"
                                >
                                    <option value="" disabled selected>
                                        Select Budget
                                    </option>
                                    <option value="Under $100,000">Under $100,000</option>
                                    <option value="$100,000 - $200,000">$100,000 - $200,000</option>
                                    <option value="$200,000 - $300,000">$200,000 - $300,000</option>
                                    <option value="$300,000 - $500,000">$300,000 - $500,000</option>
                                    <option value="Over $500,000">Over $500,000</option>
                                </select>
                                {errors.budget && (
                                    <p className="text-red-500 mt-1 text-left">This field is required.</p>
                                )}
                            </div>

                            <div className="flex justify-center">
                                <button
                                    className="p-5 border border-[#126CB3] text-white bg-[#126CB3] hover:text-white my-font mt-5 submit-button"
                                    type="submit"
                                >
                                    <span className="" style={{ marginRight: "10px" }}>
                                        Find deals now!
                                    </span>
                                </button>
                            </div>
                        </motion.form>
                    </div>
                </div>
            </section>
        </div>

    );
};

export default Contact;
