import React from 'react';
import { Link } from 'react-router-dom';
import AllRehabbedPropertiesButton from './AllRehabbedPropertiesButton';
import house1 from '../assets/img/houses/house1lg.png';
import house2 from '../assets/img/houses/house2lg.png';
import house3 from '../assets/img/houses/house3lg.png';
import house4 from '../assets/img/houses/house4lg.png';
import house5 from '../assets/img/houses/house5lg.png';
import house10 from '../assets/img/houses/house10lg.png';
import AllDevelopmentPropertiesButton from './AllDevelopmentPropertiesButton';
import AllFlexibleRentalPropertiesButton from './AllFlexibleRentalPropertiesButton';

const rehabbedProperties = [
    {
        url: 'https://www.redfin.com/IL/Des-Plaines/403-Woods-Rd-60016/home/13666150',
        // url: '/403-woods-road',
        image: house1,
        address: '403 Woods Rd, Des Plaines, IL 60016',
        rooms: '7',
    },
    {
        url: 'https://www.redfin.com/IL/Morton-Grove/7212-Beckwith-Rd-60053/home/13676781',
        // url:'/7212-beckwith-road',
        image: house2,
        address: '7212 Beckwith Rd, Morton Grove, IL 60053',
        rooms: '4',
    },
    {
        url: 'https://www.redfin.com/MI/Troy/2909-Bolingbroke-Dr-48084/home/93117114',
        // url:'/2909-bolingbroke-drive',
        image: house3,
        address: '2909 Bolingbroke Dr, Troy, MI 48084',
        rooms: '3',
    },
    // Add other rehabbed property objects here
];

const developedProperties = [
    {
        url: 'https://www.redfin.com/IL/Des-Plaines/403-Woods-Rd-60016/home/13666150',
        // url:'/403-woods-road-past',
        image: house10,
        address: '403 Woods Rd, Des Plaines, IL 60016',
        rooms: '7',
        description: '❖ Our management acquired this property after it had caught on fire. We then redeveloped it and professionally interior designed/rehabbed the property under the direction of our lead interior designer to bring life to this priceless abode.',
    },
    // Add other developed property objects here
];

const vacationRentals = [
    {
        url: 'https://www.redfin.com/IL/Park-Ridge/2160-Woodview-Ln-60068/home/13668440',
        // url:'/2160-woodview-ln-furnished',
        image: house4,
        address: '2160 Woodview Ln, Park Ridge, IL 60068',
        rooms: '5',
        description: '❖ This property has been rehabbed and placed on the short-term rental market.',
    },
    // Add other vacation rental objects here
];

const PropertyBox = ({ property }) => {
    const { url, image, address, rooms, description } = property;
    return (
        <a href={url}>
            <div className="bg-white rounded-lg p-5 text-center border border-gray-300 hover:bg-gray-100">
                <img src={image} alt="Home" className="mx-auto mb-3" style={{ height: '195px', width: '475px' }} />
                <h3 className="font-bold text-black my-font">{address}</h3>
                <p className="text-black my-font text-xs">{rooms} rooms</p>
                {description && <p className="text-black my-font text-xs text-left mt-3">{description}</p>}
            </div>
        </a>
    );
};

const HomeProperties = () => {
    return (
        <div className="container mx-auto px-5 mt-3 mb-10">
            <div className="text-center">
            <h1 className='my-font text-[2rem] mb-3 text-gray-400 '><span className='font-bold text-[35px] text-[#126CB3] justify-center items-center flex'>Our Rehabbed Properties</span> </h1></div>
            <div className="sm:grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {rehabbedProperties.map((property, index) => (
                    <PropertyBox key={index} property={property} />
                ))}
            </div>
            <br />
            <div className='justify-center items-center flex'>
                <AllRehabbedPropertiesButton />
            </div>

            <div className="text-center">
            <h1 className='my-font text-[2rem] mb-3 text-gray-400 mt-20'> <span className='text-[#126CB3] font-bold text-[35px] justify-center items-center flex'>Past Developed/Under Development</span></h1></div>
            <div className="sm:grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4">
                {developedProperties.map((property, index) => (
                    <PropertyBox key={index} property={property} />
                ))}
            </div>
            <br />
            <div className='justify-center items-center flex'>
                <AllDevelopmentPropertiesButton />
            </div>
            <br />

            <div className="text-center">
            <h1 className='my-font text-[2rem] mb-3 text-gray-400 mt-20'> <span className='text-[#126CB3] font-bold text-[35px] justify-center items-center flex'>Our Furnished Flexible Rentals</span></h1></div>
            <div className="sm:grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4">
                {vacationRentals.map((property, index) => (
                    <PropertyBox key={index} property={property} />
                ))}
            </div>
            <br />
            <div className='justify-center items-center flex'>
                <AllFlexibleRentalPropertiesButton/>
            </div>
            <br />
        </div>
    );
};

export default HomeProperties;
