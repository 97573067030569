import React from 'react';

const ServiceOperations = () => {
    return (
        <div className='container mx-auto sm:flex sm:flex-col sm:items-center mt-3 mb-10 text-center'>
            <h1 className='my-font text-2xl mb-3 text-gray-400 font-bold'><span className='font-bold text-5xl text-[#126CB3]'>Operating in major cities across the USA, and growing!</span></h1>
            {/* 
            <p className="text-lg my-3 my-font text-gray-800 mb-10">12,000 units worldwide and 1,500+ units under lease in 100 buildings nationwide</p>
            <ul className='list-none flex gap-10'>
                <li className='text-black hover:text-[#126CB3]'>Chicago</li>
                <li className='text-black hover:text-[#126CB3]'>Seattle</li>
            </ul>
            */}
        </div>
    );
}

export default ServiceOperations;
