import React from 'react'

const LineBreaker = () => {
    return (
        <div className="border-b-2 border-gray-300"></div>
        
        
    )
}

export default LineBreaker